import React from "react";

const Footer = () => {
  return (
    <section className="footer">
      <p>Parabellum © 2018 All rights reserved. Cookies policy </p>
    </section>
  );
};

export default Footer;
